import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import NotFoundBox from '@/components/404/section/NotFoundBox';
import BottomMenu from '@/components/common/section/BottomMenu';

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <FirstView pageTitle="お探しのページは存在しません。" fileName="404" />
    <NotFoundBox />
    <BottomMenu />
  </Layout>
);

export default NotFound;
