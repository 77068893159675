/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFoundBox: {
      padding: `1rem 0`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.background.default} 100%)`,
    },
    notFondPara: {
      width: `90%`,
      margin: `0 auto`,
      maxWidth: `1200px`,
      padding: `0 ${theme.spacing(2)}px`,
      color: `#000000`,
    },
  }),
);

const NotFoundBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.notFoundBox}>
      <Typography className={classes.notFondPara}>
        お探しのページは存在しません。
        <br />
        URLをご確認の上、再度お越しください。
      </Typography>
    </section>
  );
};

export default NotFoundBox;
